@import 'src/styles/scss/variables';

.wrapper {
  padding: $spacing-xs $spacing-xl;
  min-height: 48px;

  & p {
    white-space: nowrap;
  }
}

.button-margin {
  margin-left: $spacing-m;
}