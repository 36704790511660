@import 'src/styles/scss/variables';

.container {
  position: relative;

  &__icon {
    position: relative;
    z-index: 2;
    cursor: pointer;
    path {
      fill: $palette-grey400;
    }

    &:hover {
      path {
        fill: $palette-grey900;
      }
    }
  }
}

.backdrop {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: $radius-round;
  transition: all 0.2s ease-in-out;
  z-index: 1;

  &--hovered {
    width: 24px;
    height: 24px;
    background: rgba(0, 0, 0, 0.05);
  }
}

.tooltip {
  position: fixed;
  z-index: calc($layer-modal * 2);

  background-color: $palette-grey900;
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
  border-radius: $radius-s;
  padding: $spacing-s $spacing-m;

  &::before {
    content:'';
    display: block;
    width:0;
    height:0;
    position: absolute;    
  }

  &__right {
    &::before {
      left: -8px;
      top: 50%;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent; 
      border-right: 8px solid $palette-grey900;
    }
  }

  &__left {
    &::before {
      right: -8px;
      top: 50%;
      transform: translateY(-50%);
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent; 
      border-left: 8px solid $palette-grey900;
    }
  }

  &__top {
    &::before {
      right: calc(50% - 8px);
      bottom: -10px;
      transform: translateY(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-top: 8px solid $palette-grey900;
    }
  }

  &__top-left {
    &::before {
      left: 16px;
      bottom: -10px;
      transform: translateY(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-top: 8px solid $palette-grey900;
    }
  }

  &__bottom {
    &::before {
      right: calc(50% - 8px);
      top: -8px;
      transform: rotate(180deg) translateY(-50%);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-top: 8px solid $palette-grey900;
    }
  }

  &__bottom-left {
    &::before {
      left: 16px;
      top: -8px;
      transform: rotate(180deg);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-top: 8px solid $palette-grey900;
    }
  }

  &__bottom-right {
    &::before {
      right: 16px;
      top: -8px;
      transform: rotate(180deg);
      border-left: 8px solid transparent;
      border-right: 8px solid transparent; 
      border-top: 8px solid $palette-grey900;
    }
  }
}