@import 'src/styles/scss/variables';

.item {
  padding: $spacing-xxs $spacing-xs;
  border-radius: $radius-s;

  &.active {
    background-color: $palette-primary100;
  }

  &.failed {
    background-color: $palette-body;
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  &__chevron-wrapper {
    margin: 0 $spacing-xxs;

    & svg {
      path {
        fill: $palette-grey300;
      }
    }
  }

  span {
    white-space: nowrap;
  }
}