@import 'src/styles/scss/variables';

.icon-container {
  border-radius: $radius-xxl;

  &.l {
    width: 96px;
    height: 96px;
  }

  &.m {
    width: 48px;
    height: 48px;
  }

  &--info {
    background-color: $palette-primary50;

    svg {
      path {
        fill: $palette-primary600;
      }
    }
  }

  &--alert {
    background-color: $palette-alert50;

    svg {
      path {
        fill: $palette-alert600;
      }
    }
  }

  &--success {
    background-color: $palette-success50;

    svg {
      path {
        fill: $palette-success600;
      }
    }
  }

  &--waiting {
    background-color: $palette-warning100;

    svg {
      path {
        fill: $palette-warning600;
      }
    }
  }

  &--lock {
    background-color: #ffffff;

    svg {
      path {
        fill: $palette-grey500;
      }
    }
  }

  &--archive {
    background-color: #ffffff;

    svg {
      path {
        fill: $palette-alert600;
      }
    }
  }

  &--common {
    border: 1px solid $palette-grey200;
  }
}

