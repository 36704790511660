@import 'src/styles/scss/variables';

.column-buttons {
  cursor: pointer;

  svg {
    width: 16px;
    height: 16px;
  }

  &__bin {
    path {
      stroke: $palette-alert600;
    }
  }
}

.role-assignment-status {
  padding: $spacing-xxs $spacing-xs;
  border-radius: $radius-xs;

  &.COMPLETED {
    background: $palette-success100;
  }

  &.TO_COMPLETE,
  &.WAITING {
    background: $palette-warning100;
  }

  &.INSUFFICIENT {
    background: $palette-alert100;
  }
}