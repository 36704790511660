@import 'src/styles/scss/variables';

.wrapper {
  padding: calc($spacing-s - 2px) $spacing-xl calc($spacing-s - 3px) $spacing-l;
}

.list {
  display: flex;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
  
    & a {
      cursor: pointer;
      line-height: 28px;
    }
  
    & a > span:hover {
      color: $palette-grey900;
      background: $palette-grey100;
      border-radius: $radius-s;
    }
  }
}

.chevron {
  cursor: default;
  display: flex;
  align-items: center;

  svg {
    path {
      fill: $palette-grey400;
    }
  }
}

.lockFilled {
  path {
    fill: $palette-grey500;
  }
}