@import 'src/styles/scss/variables';

.text {
  font-size: 16px;
  font-style: normal;
  color: $palette-grey900;
  font-weight: 400;
  line-height: 24px;
  
  &__overline-m {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
  }
  
  &__overline-s {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 14px;
  }

  &__label-l {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__label-xl{
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }

  &__label {
    font-weight: 600;
  }

  &__label-s {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__label-xs {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &__label-xxs {
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
  }

  &__text {
    font-size: 16px;
  }

  &__text-bold {
    font-weight: 700;
  }

  &__text-small {
    font-size: 14px;
    line-height: 20px;
  }

  &__text-xs {
    font-size: 12px;
    line-height: 16px;
  }

  &__text-xxs {
    font-size: 10px;
    line-height: 14px;
  }

  &__text-italic {
    font-style: italic;
  }
}

.header-text {
  font-size: 30px;
  font-style: normal;
  color: $palette-grey900;
  font-weight: 700;
  line-height: 44px;

  &__hero {
    font-size: 48px;
    line-height: 72px;
  }

  &__header {
    font-size: 30px;
    font-weight: 100;
  }

  &__header-m {
    font-size: 24px;
    line-height: 32px;
  }

  &__header-s {
    font-size: 20px;
    line-height: 28px;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.clickable {
  cursor: pointer;
}

.line-restriction {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}