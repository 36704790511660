@import 'src/styles/scss/variables';

.card {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: $spacing-m;
  align-items: center;
  box-sizing: border-box;
  border-radius: $radius-s;
  padding: calc($spacing-m - 1px) $spacing-m;
  cursor: pointer;
  border: 1px solid $palette-grey200;

  &--approve {
    border: 1px solid $palette-success600;
    outline: 1px solid $palette-success600;
  }

  &--disapprove {
    border: 1px solid $palette-alert600;
    outline: 1px solid $palette-alert600;
  }

  &--rework {
    border: 1px solid $palette-rework600;
    outline: 1px solid $palette-rework600;
  }

  &--disabled {
    background-color: $palette-grey50;
    cursor: default;
    pointer-events: none;
  }
}

.circle {
  width: $spacing-l;
  height: $spacing-l;

  border: 1px solid $palette-grey200;
  border-radius: $radius-round;
}

.close-icon {
  path {
    fill: $palette-alert600;
  }

  path:first-child {
    stroke: $palette-alert600;
  }
}
