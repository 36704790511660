@import 'src/styles/scss/variables';

.checkbox {
  &__label {
    margin-bottom: $spacing-m;
  }

  &__holder {
    display: flex;
    margin-bottom: $spacing-m;
  
    &:last-child {
      margin-bottom: 0;
    }
  }

  &__styled-checkbox {
    display: inline-block;
    width: $spacing-l;
    height: $spacing-l;
    border: 1px solid $palette-grey200;
    border-radius:  $radius-s;
    transition: all 150ms;
    background: $palette-body;

    &.checked {
      border: none;
    }
  }

  &__styled-checkbox-disabled {
    background: $palette-grey100;
  }

  &__hide {
    display: none;
  }

  &__container {
    display: inline-block;
    vertical-align: middle;
    height: $spacing-l;
    cursor: pointer;


    &.disabled {
      cursor: default;
    }
  }

  &__standard {
    margin-left: $spacing-xs;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    padding: $spacing-xxs $spacing-xs;
    background-color: $palette-grey100;
    height: 12px;
    box-sizing: content-box;
    border-radius: $radius-xs;
  }

  &__icon {
    fill: none;
    stroke: white;
    stroke-width: 2px;
  }
}
