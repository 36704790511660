@import 'src/styles/scss/variables';

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $palette-body;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
}