@import 'src/styles/scss/variables';

.header {
  border-bottom: 1px solid $palette-grey200;
  margin: 0;
  top: 0;
  position: fixed;
  display: grid;
  grid-template-columns: 1fr max-content;

  z-index: $layer-header;
  left: $sidebar-width;
  width: calc(100vw - $sidebar-width);
  background-color: $palette-body;
  transition: margin-top 0.5s ease-in-out;

  &.mode-stepper {
    margin-top: -48px;
  }

  &.hide-sidebar {
    width: 100%;
    left: 0;
  }
}