@import 'src/styles/scss/variables';
.inside-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $layer-loader;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  transition: opacity 0.2s;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;

  &.in-modal {
    margin: auto;
    display: block;
    shape-rendering: auto;
  }
}


$uib-size: 40px;
$uib-color: black;
$uib-speed: 2s;
$uib-bg-opacity: 0;

.container {
  transform-origin: center;
  animation: rotate $uib-speed linear infinite;
  will-change: transform;
  overflow: visible;
}

.car {
  fill: none;
  stroke: $uib-color;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: stretch calc($uib-speed * 0.75) ease-in-out infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;

  &.white{
    stroke: $palette-body;
  }
}

.track {
  fill: none;
  stroke: $uib-color;
  opacity: $uib-bg-opacity;
  transition: stroke 0.5s ease;

  &.white{
    stroke: $palette-body;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes stretch {
  0% {
    stroke-dasharray: 0, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 75, 150;
    stroke-dashoffset: -25;
  }
  100% {
     stroke-dashoffset: -100;
  }
}
