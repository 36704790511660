p.Heading1,
li.Heading1,
div.Heading1,
p.Heading3,
li.Heading3,
div.Heading3 {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-left: 56.7pt;
  margin-bottom: 0.0001pt;
  text-indent: -56.7pt;
  text-align: both;
  font-size: 12pt;
  color: #000000;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 0px;
}

p.ListParagraph,
li.ListParagraph,
div.ListParagraph {
  margin-top: 0cm;
  margin-right: 0cm;
  margin-left: 36pt;
  margin-bottom: 0.0001pt;
  text-align: both;
  font-size: 12pt;
  font-family: 'Arial';
  line-height: 1.5;
  margin-bottom: 0px;
}

#page-break {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #7f97a2;
  line-height: 1px;
  margin: 40px 0;
}
#page-break span {
  color: #7f97a2;
  background: #F1F2F4;
  padding: 0 10px;
}

p.berschrift1, li.berschrift1, div.berschrift1 {
  mso-style-name: "heading 1";
  margin-top: 0cm;
  margin-right: 0cm;
  margin-left: 3.5pt;
  margin-bottom: 0.0001pt;
  text-indent: -0.5pt;
  text-align: both;
  font-size: 10pt;
  font-family: "Arial";
  color: #000000;
  font-weight: bold;
  line-height: 1.0791666666666666;
  margin-bottom: 0.2px
}

p.Standard, li.Standard, div.Standard {
  mso-style-name: "Normal";
  margin-top: 0cm;
  margin-right: 0cm;
  margin-left: 24.05pt;
  margin-bottom: 0.0001pt;
  text-align: both;
  font-size: 10pt;
  font-family: "Arial";
  color: #000000;
  line-height: 1.4791666666666667;
  margin-bottom: 7.25px;
}
