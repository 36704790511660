.container {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  &__content {
    position: relative;
  }
}

.spinner {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner::after,
.spinner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
  border: 4px solid #f1f2f4;
}

.spinner::before {
  border-right: 2px solid #0d1117;
  animation: spin 1s 1s linear infinite;
}

.spinner::after {
  border-bottom: 4px solid #0d1117;
  animation: spin 1s 0.15s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}