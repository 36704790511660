@import 'src/styles/scss/variables';

.list {
  width: 100%;
  display: flex;
  margin: 0 $spacing-m;

  &__item {
    padding: calc($spacing-s + 2px) $spacing-m; 
    cursor: pointer;
    position: relative;
  }
}

.text {
  &:after {
    content: '';
    position: absolute;
    display: none;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: calc(100% - $spacing-xl);
    height: 2px;
    background-color: $palette-primary600;
  }

  &--active {
    &:after {
      display: block;
    }
  }
}
