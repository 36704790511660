@import 'src/styles/scss/variables';

$box_width: 384px;
$box_height: 128px;

.notification {
  position: fixed;
  top: $spacing-m;
  width: $box_width;
  left: calc(50% - $box_width / 2);
  z-index: 101;

  &__box {
    width: $box_width;
    min-height: $box_height;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: $palette-body;
    box-shadow: 0 0 8px rgba(31, 41, 55, 0.1);
    border-radius: $radius-s;
    transition: all 0.6s ease-out;
    opacity: 1;
    transform: translateX(0);

    &-content {
      width: 100%;
      padding-top: calc($spacing-m + 4px);
      padding-bottom: $spacing-m;

      &-close-icon {
        padding: calc($spacing-m + 4px);
      }
    }
  }
}

.icon {
  padding: calc($spacing-m + 4px);

  &-success {
    svg {
      path {
        stroke: $palette-success600;
      }
    }
  }

  &-error {
    svg {
      path {
        stroke: $palette-alert600;
      }
    }
  }

  &-warning {
    svg {
      path {
        stroke: $palette-warning600;
      }
    }
  }

  &-info {
    svg {
      path {
        stroke: $palette-primary600;
      }
    }
  }
}