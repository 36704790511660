@import 'src/styles/scss/variables';

.container {
  position: relative;
  text-align: start;
  width: 100%;
  margin: 0;
  
  label {
    margin-bottom: $spacing-xxs;
    display: flex;
    align-items: center;
    width: max-content;
    gap: $spacing-xxs;

    span {
      font-style: italic;
    }
  }
}
