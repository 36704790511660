// variables for SCSS components 
// must be the same as in TS files

$radius-xs: 3px;
$radius-s: 6px;
$radius-m: 12px;
$radius-l: 24px;
$radius-xxl: 100px;  
$radius-round: 50%;

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-m: 16px;
$spacing-l: 24px;
$spacing-xl: 32px;
$spacing-xxl: 48px;
$spacing-d-xl: 64px;

$layer-minimal: 1;
$layer-header: 6;
$layer-loader: 9999;
$layer-sidebar: 10000;
$layer-slider: 10001;
$layer-modal: 10002;

$stroke-width400: 4px;

$palette-body: #ffffff;
$palette-bodyOpacity10: rgba(255, 255, 255, 0.1);
$palette-bodyOpacity15: rgba(255, 255, 255, 0.15);
$palette-bodyOpacity50: rgba(255, 255, 255, 0.5);
$palette-accent: #E5DE17;
$palette-accentColor: #161616;
$palette-ternary: #000000;
$palette-ternaryOpacity10: rgba(0, 0, 0, 0.1);
$palette-ternaryOpacity15: rgba(0, 0, 0, 0.15);
$palette-ternaryOpacity50: rgba(0, 0, 0, 0.5);
$palette-ternaryOpacity5: rgba(0, 0, 0, 0.05);
$palette-aqua100: #DFEAF2;
$palette-aqua800: #263B4C;
$palette-grey50: #F9FAFB;
$palette-grey100: #F1F2F4;
$palette-grey200: #E2E4E9;
$palette-grey300: #D4D8DE;
$palette-grey400: #9CA3AF;
$palette-grey500: #6B7280;
$palette-grey600: #4B5563;
$palette-grey700: #374151;
$palette-grey800: #1F2937;
$palette-grey900: #0D1117;
$palette-grey1000: #11161D;
$palette-alert50: #FEF6F6;
$palette-alert100: #fde7e7;
$palette-alert600: #F23535;
$palette-alert700: #E50F0F;
$palette-alert800: #B50C0C;
$palette-primary50: #F6FAFE;
$palette-primary100: #E9F3FC;
$palette-primary200: #D3E7F8;
$palette-primary300: #BDDCF5;
$palette-primary500: #4299E1;
$palette-primary600: #2180CF;
$palette-primary700: #1A65A3;
$palette-primary800: #134A77;
$palette-primary900: #0C2E4B;
$palette-verde200: #D0E9DE;
$palette-verde800: #375045;
$palette-success50: #F8FCF9;
$palette-success100: #EDF8F1;
$palette-success200: #DAF1E3;
$palette-success500: #48BB78;
$palette-success600: #389860;
$palette-success700: #2B7249;
$palette-success800: #1D4D31;
$palette-warning100: #FEF0CD;
$palette-warning200: #FEF0CD;
$palette-warning500: #FBBF28;
$palette-warning600: #EBAA05;
$palette-warning800: #876203;
$palette-verde700: #527767;
$palette-green: #3EB261;
$palette-green500: #48BB78;
$palette-rework600: #EB7305;
$palette-rework100: #FEF2E6;

$header_height: 48px;
$un_logged_header_height: 72px;
$sidebar-width: 72px;
$sidebar-expanded-width: 280px;

$venture-pitch-mobile-work-space-width-large: 378px;
$venture-pitch-mobile-work-space-width-small: 320px;
$venture-pitch-mobile-footer-height: 93px;
$venture-pitch-mobile-footer-height-small: 70px;