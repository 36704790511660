@import 'src/styles/scss/variables';

.top-section {
  padding: $spacing-l 0;

}

.avatar-section {
  position: relative;
  width: 96px;
  height: 96px;
}



.progress-section {
  padding: $spacing-l;
  border-radius: $radius-m;
  border: 1px solid $palette-grey200;
}

.avatar-badge {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: $palette-body;
  position: absolute;
  bottom: 2px;
  right: 2px;

  width: 24px;
  height: 24px;
  border-radius: 6px;

  &.small {
    width: 16px;
    height: 16px;
    border-radius: 3px;
    bottom: -2px;
    right: -2px;
  }

  &.black {
    background-color: $palette-grey900;
  }

  &__icon {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    background-color: $palette-body;
    border-radius: 50px;

    &.black {
      background-color: $palette-grey900;
    }  
  }
}

.close {
  width: 24px;
  height: 24px;
  position: absolute;
  right: $spacing-l;
  top: $spacing-l;
  cursor: pointer;
}