@import 'src/styles/scss/variables';

.dropdown-indicator {
  display: flex;
  align-items: center;
  width: 36px;

  &__clock {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    margin-right: $spacing-s;
  }

  &--small {
    width: 16px;
  }

  &__icon {
    &--disabled {
      path {
        stroke: $palette-grey500;
      }
    }
  }
}

.indicator {
  display: flex;
  cursor: pointer;

  svg {
    path {
      fill: $palette-grey400;
    }
  }
}

.dropdown-indicator-chevron-16px {
  path {
    fill: $palette-grey400;
  }

  &--open {
    path {
      fill: $palette-grey800;
    }  
  }

  &__simple {
    display: flex;
    align-items: center;
  
    svg {
      path {
        fill: $palette-grey500;
      }
    }
  }

  &.black {
    svg {
      path {
        fill: $palette-grey900;
      }
    }
  }
}


.control-step-progress {
  background-color: $palette-primary600;
  border-radius: $radius-l;
  padding: 0 $spacing-xs;
}

.header-stepper-menu-list {
  &--header {
    padding: $spacing-xs $spacing-m $spacing-l;
  }
}

.header-stepper-option {
  padding: $spacing-xs $spacing-m;

  &.active-option {
    background-color: $palette-bodyOpacity15;
  }

  &:hover {
    background-color: $palette-bodyOpacity15;
  }
}

.colored-circle {
  width: 24px;
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  background-color: transparent;

  &::after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 28px;
    height: 28px;
    background-color: transparent;
    outline: 2px solid #3F8AE2;
    border-radius: 50%;
  }

  &__active {
    &::after {
      display: block;
    }
  }
}

.menu-list {
  height: 8px;
  border-bottom: 1px solid $palette-grey700;

  &__footer {
    padding: $spacing-xs $spacing-m;
    cursor: pointer;
    margin-top: $spacing-xs;
  
    &:hover {
      background: $palette-grey700;
    }
  }

  &__circle {
    width: 20px;
    height: 20px;
    background: $palette-body;
    border-radius: $radius-round;
  }
}

.venture-option {
  padding: $spacing-xs $spacing-m;
}

.menu-button-wrapper {
  margin-top: $spacing-m;
}

.invite-menu-list {
  padding: calc($spacing-s - 2px) $spacing-m;
}

.invite-user-option { 
  padding: $spacing-xs $spacing-m;
}

.control-icon {
  margin-left: $spacing-xs;
  background-color: $palette-grey100;
  width: 24px;
  height: 24px;
  border-radius: $radius-round;
}

.dropdown-indicator-clear {
  display: flex;
  margin-right: $spacing-xs;
  cursor: pointer;
}

.single-value-with-image-name {
  display: flex;
  margin-left: $spacing-xs;
}

.option-with-image {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__user-info {
    display: flex;
    flex-direction: column;
  }

  &__info {
    width: 100%;
  }
  
  &-selected {
    path {
      fill: $palette-primary600;
    }
  }

  &.padding {
    padding: $spacing-xs $spacing-m;
  }
}

.menu-icons {
  path {
    stroke: $palette-body;
  }
}

.soft-registered-user-menu {
  padding: $spacing-xs $spacing-m;

  &.pointer {
    cursor: pointer;

    &:hover {
      background: $palette-grey700;
    }
  }
}

.header-menu {
  width: 480px !important;
  right: 0px;
  border-radius: $radius-m !important;

  @media (max-width: 480px) {
    width: 100vw !important;
    right: -$spacing-m;
    top: -30px !important;
    border-radius: 0px !important;
  }
}

.offline-investment-document-menu {
  padding: $spacing-xs $spacing-m;
  cursor: pointer;

  &:hover {
    background: $palette-grey50;
  }
}