@import 'src/styles/scss/variables';

.aq-image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: $palette-grey100;
}
