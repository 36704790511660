@import 'src/styles/scss/variables';
@import 'src/styles/scss/keyframes';

$shift: 200px;

.container {
  position: fixed;
  border-radius: $radius-s;
  width: 100%;
  background-color: $palette-grey900;

  &__icon {
    animation: spin 3.2s linear infinite;
  }
}

.box {
  overflow: hidden;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: $spacing-m;
  padding: $spacing-m;
  border-radius: $radius-s;
  transition: all 0.3s ease;
  min-height: 56px;
  box-shadow: 0px 4px 16px rgba(31, 41, 55, 0.1);
  background-color: $palette-grey900;
  z-index: calc($layer-header * 2);

  position: absolute;
  top: -$shift;
  left: 0;

  &--hide {
    animation: all 0.4s forwards ease-in-out;
    animation-delay: .2s;
    top: -$shift;
  }

  &--show {
    animation: all 0.4s forwards ease-in-out;
    top: 0;
  }

  span {
    text-align: center;
  }
  
  &__with-right-button {
    grid-template-columns: max-content 1fr max-content;

    span {
      text-align: start;
    }
  }
}
