@import 'src/styles/scss/variables';

.public {
  min-height: 100vh;
  background-color: $palette-body;
  display: flex;
  position: relative;
}

.app {
  display: grid;
  grid-template-columns: [sidebar-start] $sidebar-width [sidebar-end content-start] 1fr [content-end];
  grid-template-rows: [header-start] $header_height [header-end content-start] 1fr [content-end];
  height: 100vh;

  &__main {
    background-color: $palette-body;
    grid-column: content-start / content-end;
    grid-row: content-start / content-end;
  }

  &.hide-sidebar {
    grid-template-columns: [sidebar-end content-start] 1fr [content-end];
    grid-template-rows: [header-start] $header_height [header-end content-start] 1fr [content-end];
  }
}